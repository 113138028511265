import { Model, Attr, HasMany } from 'spraypaint';
import { LinkPropsBuilder } from '@/types';
import Status from '@common/components/statuses/Status';
import ApplicationRecord from './ApplicationRecord';
import type MultipartOperationsPart from './MultipartOperationPart';

export type StatusEnum = 'pending' | 'success' | 'error';

@Model()
class MultipartOperationOperation extends ApplicationRecord {
  static jsonapiType = 'multipart_operation-operations';

  // Attributes
  @Attr({ persist: false }) name!: string;
  @Attr({ persist: false }) status!: StatusEnum;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;

  @Attr({ persist: false }) reportTxt!: string;

  // Relationships
  @HasMany('multipart_operation-parts') parts!: Array<MultipartOperationsPart>;

  linkProps: LinkPropsBuilder = ({ pathBuilder }) => ({
    children: (
      <>
        <Status statusType={this.status} inline /> {this.name}
      </>
    ),
    to: pathBuilder(`/operations/${this.id}`),
  });
}

export default MultipartOperationOperation;
