// api/AuditsExportsDueInvoicesExport.ts
import { Model, Attr, HasOne } from 'spraypaint';
import type AuditsExport from '@spraypaint/audits/AuditsExport';
import { MultipartOperationOperation } from '@/app/spraypaint';
import ApplicationRecord from '../../ApplicationRecord';

@Model()
class AuditsExportsDueInvoicesExport extends ApplicationRecord {
  static jsonapiType = 'audits-exports-due_invoices_exports' as const;

  // Attributes
  @Attr() thresholdDate!: string;

  // Extra-attributes

  // Relationships
  @HasOne('audits-exports') export!: AuditsExport;
  @HasOne('multipart_operation-operations')
  operation!: MultipartOperationOperation;
}

export default AuditsExportsDueInvoicesExport;
