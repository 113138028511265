import { useTranslation } from 'react-i18next';
import { MenuType } from '@/modules/layout/types/MenuType';
import { RoleType } from '../../authentification/types/RoleType';
import { TdbIcon } from '../../../app/assets/icons/menu';
import { hasArrayIntersection } from '../../common/utils/array.utils';

export const constantAccountantMenu = (
  roles: Array<RoleType>,
): Array<MenuType> => {
  const { t } = useTranslation();
  const display = hasArrayIntersection(['support', 'accountant'], roles);
  const displayForAdmin = hasArrayIntersection(
    ['support', 'accountant', 'manager'],
    roles,
  );

  if (!display && !displayForAdmin) return [];

  return [
    {
      type: 'separator' as const,
      title: t('front.menu.separator.accounting'),
      displaySeparator: displayForAdmin,
    },
    {
      title: t('front.menu.export'),
      toV1: 'accounting_exports/new',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.audits'),
      to: 'audits',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.import_charges'),
      toV1: 'charges_imports/new',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.bank_transfer'),
      to: 'bank_transfer',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.bank_reconciliation'),
      to: 'bank_reconciliation',
      icon: TdbIcon,
      canBeDisplay: displayForAdmin,
      customClass: !display ? 'mb-10' : '',
    },
    {
      title: t('front.menu.accounting_codes'),
      to: 'accounting_codes',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.accounting_journal_exports'),
      to: 'accounting_journal_exports',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.factor_reconciliation'),
      to: 'factor_reconciliation',
      icon: TdbIcon,
      canBeDisplay: displayForAdmin,
      customClass: !display ? 'mb-10' : '',
    },
    {
      title: t('front.menu.factor_client_payment_files'),
      to: 'factor_reconciliation/client_payment_files',
      icon: TdbIcon,
      canBeDisplay: displayForAdmin,
      customClass: !display ? 'mb-10' : '',
    },
    {
      title: t('front.menu.factor_authorizations'),
      to: 'factor_authorizations',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.factor_outstanding_invoices'),
      to: 'factor_outstanding_invoices',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      type: 'separator' as const,
      title: t('front.menu.separator.payslip'),
      displaySeparator: display,
    },
    {
      title: t('front.menu.payslips_dashboard'),
      to: 'payslips',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.silae_income_tax_imports'),
      toV1: 'silae_income_tax_imports',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.silae_imports'),
      toV1: 'silae_imports',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.health_package_reconciliations'),
      toV1: 'health_package_reconciliations',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.payslips_exports'),
      toV1: 'payslips_export',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.payslips_validation_imports'),
      toV1: 'payslips_validation_imports',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.sepa_imports'),
      toV1: 'sepa_imports',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.stc_imports'),
      toV1: 'stc_imports',
      icon: TdbIcon,
      canBeDisplay: display,
    },
  ];
};
