import type { FunctionComponent } from 'react';

export interface LocalDateTimeProps {
  date: Date | null | undefined;
}

export const LocalTimeDate: FunctionComponent<LocalDateTimeProps> = ({
  date,
}) => {
  if (!date) {
    return null;
  }

  return <>{date.toLocaleString()}</>;
};
