import ReactSelect, {
  DropdownIndicatorProps,
  GroupBase,
  MultiValue,
  Props as ReactSelectProps,
  SingleValue,
} from 'react-select';
import classNames from 'classnames';
import { isArray } from 'lodash';

import { Label } from '@common/components/inputs/components';
import { SelectArrows } from './components/SelectArrows';

export type SelectValueType = { value: string; label: string };

export type FSelect<T> = MultiValue<T> | SingleValue<T>;

export type SelectProps<T, IsMulti extends boolean = false> = ReactSelectProps<
  T,
  IsMulti
> & {
  label?: string;
  labelClassName?: string;
  className?: string;
  selectClassName?: string;
  displayCol?: boolean;
};

const DropdownIndicator = <T, IsMulti extends boolean>({
  selectProps,
}: DropdownIndicatorProps<T, IsMulti, GroupBase<T>>) => (
  <SelectArrows
    isOpen={selectProps.menuIsOpen}
    isLoading={selectProps.isLoading}
  />
);

export function isMulti<T>(val: FSelect<T>): val is MultiValue<T> {
  return isArray(val);
}

export type Option = { label: string; value: string };
export const Select = <T extends Option, IsMulti extends boolean = false>({
  isDisabled,
  required,
  className,
  options,
  value,
  placeholder,
  onChange,
  isLoading,
  label,
  isSearchable = false,
  isClearable = true,
  labelClassName,
  selectClassName,
  displayCol,
  ...props
}: SelectProps<T, IsMulti>) => {
  const customClassName = classNames(
    displayCol ? 'flex flex-col' : 'flex items-center',
    className,
  );
  const customDivClassName = classNames(
    'p-[8px] h-[36px] flex justify-between items-center',
    {
      'w-[310px] hover:bg-color-light-grey border-b-[1px] ': !isDisabled,
      'w-[92px] text-color-medium-grey fill-color-medium-grey bg-color-light-grey text-sm2 rounded':
        isDisabled,
      'border-color-medium-grey': !value,
      'border-color-pone-dark': value,
    },
    selectClassName,
  );

  const placeholderClassName = classNames(
    'overflow-hidden whitespace-nowrap text-ellipsis',
    {
      'text-color-medium-grey': !value,
      'text-color-pone-dark': value,
    },
  );

  const menuContainerClassName =
    'flex flex-col w-[310px] absolute top-[2px] right-[8px] overflow-y-scroll max-h-[100px] bg-white shadow-table z-50';

  const controlClassName = 'flex justify-between items-center w-full';
  const optionClassName =
    'py-[11px] hover:bg-color-light-grey text-left pl-[11px]';

  return (
    <div className={customClassName}>
      {label && (
        <Label required={required} className={labelClassName}>
          {label}
        </Label>
      )}

      <ReactSelect
        className={customDivClassName}
        classNames={{
          menuList: () => menuContainerClassName,
          placeholder: () => placeholderClassName,
          control: () => controlClassName,
          option: () => optionClassName,
        }}
        components={{
          DropdownIndicator,
        }}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        isClearable={isClearable}
        unstyled
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        options={options}
        {...props}
      />
    </div>
  );
};
