/* eslint-disable radix */
import { useCallback, useEffect, useState } from 'react';
import { FilterFn, RowData } from '@tanstack/react-table';
import { MultiValue } from 'react-select';
import { findValueOption } from '@/modules/common/components/inputs/controlledInput/ControlledSelect';
import { FilterComponentProps, PriumFilterFn } from './types';

import { Select, SelectProps, Option } from '../../inputs/Select';

export type MultiSelectFilterOptions<TValue extends string> = SelectProps<
  {
    label: string;
    value: TValue;
  },
  true
>;

export function getMultiSelectFilterFn<
  TData extends RowData,
>(): FilterFn<TData> {
  const filter: PriumFilterFn<TData> = (
    row,
    columnId,
    filterValue: unknown,
  ) => {
    const value = row.getValue(columnId);

    if (value == null) return false;

    return value === filterValue;
  };

  filter.backendFilterFn = (columnId, value: string) => ({
    [columnId]: value,
  });

  filter.resolveFilterValue = (value) => value;

  return filter;
}

export function MultiSelectFilter<TData, TValue extends string>({
  options,
  header,
  ...props
}: FilterComponentProps<TData, TValue, MultiSelectFilterOptions<TValue>>) {
  const { options: selectOptions } = options ?? {};
  const [selectValue, setSelectValue] = useState<readonly Option[]>([]);
  const { column } = header;

  useEffect(() => {
    const filterValue = column.getFilterValue() as Array<string> | undefined;
    const selectedOptions = (filterValue ?? []).reduce((acc, value) => {
      const option = findValueOption(selectOptions, value);
      if (option) {
        return [...acc, option];
      }
      return acc;
    }, [] as readonly Option[]);
    setSelectValue(selectedOptions);
  }, [column]);

  const onValueChange = useCallback(
    (value: MultiValue<Option>) => {
      setSelectValue(value);
      if (value.length === 0) {
        column.setFilterValue(undefined);
      } else {
        column.setFilterValue(value.map((v) => v.value));
      }
    },
    [column],
  );

  return (
    <Select
      {...props}
      value={selectValue}
      onChange={onValueChange}
      options={selectOptions}
      isMulti
      selectClassName="bg-white"
    />
  );
}

// Component constructor builder for MultiSelectFilter
export function multiSelectFilterWithOptions<TData, TValue extends string>(
  options: MultiSelectFilterOptions<TValue>['options'],
) {
  return function MultiSelectFilterWithOptions(
    props: FilterComponentProps<
      TData,
      TValue,
      MultiSelectFilterOptions<TValue>
    >,
  ) {
    return <MultiSelectFilter {...props} options={{ options }} />;
  };
}
