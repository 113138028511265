import AuditsExportsDueInvoicesExport from '@spraypaint/audits/exports/AuditsExportsDueInvoicesExport';
import { ControlledDateInput } from '@common/components/inputs/controlledInput/ControlledDateInput';
import { useTranslation } from 'react-i18next';
import {
  SubformComponent,
  SubCardFieldsComponent,
  SubModule,
} from '@/modules/admin/audits/components/submodules/types';
import { LineText } from '@common/components/fragments/LineText';
import { InternalLink } from '@common/components/buttons';
import { adminPathBuilder } from '@common/components/LinkLine';

export const jsonApiType = AuditsExportsDueInvoicesExport.jsonapiType;

export type Values = {
  thresholdDate: AuditsExportsDueInvoicesExport['thresholdDate'];
};

export const defaultValues: Values = {
  // Today, iso8601 format date
  thresholdDate: new Date().toISOString().split('T')[0],
};

function createExportStrategy(data: Values): AuditsExportsDueInvoicesExport {
  return new AuditsExportsDueInvoicesExport({
    thresholdDate: data.thresholdDate,
  });
}

export const FormComponent: SubformComponent = ({ control }) => {
  const { t } = useTranslation();
  return (
    <ControlledDateInput
      label={t(
        'activerecord.attributes.audits/exports/due_invoices_export.threshold_date',
        {
          ns: 'rails',
        },
      )}
      className="mt-20"
      control={control}
      defaultValue={undefined}
      name="exportStrategy.params.thresholdDate"
      stringMode
      required
    />
  );
};

export const CardFieldsComponent: SubCardFieldsComponent<
  AuditsExportsDueInvoicesExport
> = ({ strategy }) => {
  const { t } = useTranslation();

  return (
    <>
      <LineText
        label={t('activerecord.attributes.audits/export.export_strategy', {
          ns: 'rails',
        })}
        value={t('activerecord.models.audits/exports/due_invoices_export', {
          ns: 'rails',
        })}
      />{' '}
      <LineText
        label={t(
          'activerecord.attributes.audits/exports/due_invoices_export.threshold_date',
          {
            ns: 'rails',
          },
        )}
        value={strategy.thresholdDate}
      />{' '}
      <LineText
        label={t(
          'activerecord.attributes.audits/exports/due_invoices_export.operation',
          {
            ns: 'rails',
          },
        )}
        value={
          strategy.operation && (
            <InternalLink
              {...strategy.operation.linkProps({
                pathBuilder: adminPathBuilder,
              })}
            />
          )
        }
      />
    </>
  );
};

export const DueInvoicesExportSubModule: SubModule<
  Values,
  AuditsExportsDueInvoicesExport
> = {
  jsonApiType,
  defaultValues,
  FormComponent,
  CardFieldsComponent,
  createExportStrategy,
};
