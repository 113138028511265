import type { FunctionComponent } from 'react';
import { AnyControl } from '@/modules/admin/audits/components/submodules/types';
import {
  getSubModuleByJsonApiType,
  SubformModules,
} from '@/modules/admin/audits/components/submodules';

export interface AuditsExportSubformProps {
  control: AnyControl;
  selectedKey: SubformModules[number]['jsonApiType'] | null;
}

export const AuditsExportSubform: FunctionComponent<
  AuditsExportSubformProps
> = ({ control, selectedKey }) => {
  const subformModule = getSubModuleByJsonApiType(selectedKey);
  if (subformModule) {
    const SubformComponent = subformModule.FormComponent;
    return <SubformComponent control={control} />;
  }
  return null;
};
