/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Row } from '@tanstack/react-table';
import classNames from 'classnames';
import { useState } from 'react';
import {
  NormalizedOnRowClickType,
  useRowClickHandler,
} from '@common/components/table/useBuildOnRowClickHandler';
import type { TableStyle, RowSubcomponent } from '../Table';
import { MathUtils } from '../../../utils';
import { TableCell } from './TableCell';

function SubComponentLine<T>({
  row,
  RowSubcomponent,
}: {
  row: Row<T>;
  RowSubcomponent: RowSubcomponent<T> | undefined;
}) {
  if (row.id === '1010') console.log({ isExpanded: row.getIsExpanded() });
  if (!RowSubcomponent) return null;
  if (!row.getIsExpanded()) return null;

  return (
    <tr className="border-b-[3.5px] border-white">
      <td colSpan={row.getVisibleCells().length}>
        <RowSubcomponent row={row} original={row.original} />
      </td>
    </tr>
  );
}

const voidFunction = () => {};

export function TableRow<T>({
  row,
  style,
  RowSubcomponent,
  onRowClickNormalizer,
}: {
  row: Row<T>;
  style: TableStyle;
  RowSubcomponent: RowSubcomponent<T> | undefined;
  onRowClickNormalizer: (row: Row<T>) => NormalizedOnRowClickType;
}) {
  const [isHoveringRow, setIsHoveringRow] = useState(false);
  const shouldHaveBackgroundColor = MathUtils.isOdd(row.index);

  const handleOnMouseEnter = () => setIsHoveringRow(true);
  const handleOnMouseLeave = () => setIsHoveringRow(false);

  const normalizedOnRowClickType = onRowClickNormalizer(row);
  const onRowClickHandler = useRowClickHandler(normalizedOnRowClickType);

  const customRowClassName = classNames(
    'hover:bg-color-pastel-orange border-t-[3.5px] border-white',
    {
      'border-b-[3.5px]': !(RowSubcomponent && row.getIsExpanded()),
      'bg-color-light-grey  border-color-light-grey': shouldHaveBackgroundColor,
      'border-white': isHoveringRow || row.getIsSelected(),
      'bg-color-pastel-orange': row.getIsSelected(),
      'cursor-pointer': onRowClickHandler !== voidFunction,
      'h-[75px]': style === 'normal',
      'h-[40px]': style === 'compact',
    },
  );

  return (
    <>
      <tr
        onClick={onRowClickHandler}
        key={`${row.id}-row`}
        className={customRowClassName}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        {row.getVisibleCells().map((cell) => {
          const hasBorder = !cell.column.columnDef.meta?.noBorder;
          const className = classNames('min-h-[75px]', {
            'border-r-[1px] border-color-medium-light':
              hasBorder && row.depth === 0,
            'bg-color-cra-grey': row.depth > 0 && RowSubcomponent,
          });

          return (
            <td key={cell.id} className={className}>
              <TableCell
                key={cell.id}
                cell={cell}
                isHoveringRow={isHoveringRow}
              />
            </td>
          );
        })}
      </tr>
      <SubComponentLine
        key={`${row.id}-subcomponent`}
        row={row}
        RowSubcomponent={RowSubcomponent}
      />
    </>
  );
}
