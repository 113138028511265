import { createBrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { QueryClient } from '@tanstack/react-query';
import { mountInvoicesRoutes } from '@/modules/invoices';
import { mountBankTransferRoutes } from '@/modules/admin/accounting/bankTransfer';
import { mountBankReconciliationRoutes } from '@/modules/admin/accounting/bankReconciliation';
import redirectTo from '@/app/navigation/loaders/redirectTo';
import { mountDebugRoutes } from '@/modules/debug';
import { mountMultipartOperationRoutes } from '@/modules/admin/multipart_operations';
import { mountPayslipsRoutes } from '@/modules/payslip';
import { mountPerksRoutes } from '@/modules/perks';
import { mountVideoTutorialRoutes } from '@/modules/videoTutorial';
import { RoutesMounterArgs } from '@/types';
import { ErrorPage } from '@/app/navigation/components/ErrorPage';
import { NotFoundPage } from '@/app/navigation/components/NotFoundPage';
import { EmployeeLayout } from '@/app/navigation/components/EmployeeLayout';
import { AdminLayout } from '@/app/navigation/components/AdminLayout';
import { mountActivityAccountRoutes } from '@/modules/employee/activityAccount';
import { mountAccountingCodesRoutes } from '@/modules/admin/accounting_codes/routes';
import { mountUsersRoutes } from '@/modules/users/routes';
import { mountRegisteredClientsRoutes } from '@/modules/admin/registered_clients/routes';
import { mountAccountingJournalExportsRoutes } from '@/modules/admin/accounting_journal_exports/routes';
import { mountPayslipAdminRoutes } from '@/modules/admin/payslip';
import { mountSubcontractorsRoutes } from '@/modules/subcontractors/routes';
import { mountAdminInvoicesRoutes } from '@/modules/admin/invoices';
import { mountEmployeeSubcontractorsRoutes } from '@/modules/employee/subcontractors/routes';
import { mountEmployeeSubcontractorBillsRoutes } from '@/modules/employee/subcontractorBills/routes';
import { mountCapGeminiRoutes } from '@/modules/admin/capgemini/routes';
import { mountFactorTransfersRoutes } from '@/modules/admin/factor/transfers/routes';
import { mountFactorReconciliationRoutes } from '@/modules/admin/factor/reconciliation/routes';
import { mountAdminCreditsRoutes } from '@/modules/admin/credits';
import { mountSubcontractorBillsRoutes } from '@/modules/subcontractor_bills/routes';
import { mountGdprRoutes } from '@/modules/gdpr';
import { mountFactorAuthorizationRoutes } from '@/modules/admin/factor/authorizations/routes';
import { mountFactorOutstandingInvoicesRoutes } from '@/modules/admin/factor/outstanding_invoices/routes';
import {
  buildAuthenticatedLoader,
  expectProspectFilter,
  onlyAdminFilter,
} from '@/app/navigation/loaders/buildAuthenticatedLoader';
import { mountAuthenticationRoutes } from '@/modules/authentification';
import { mountWelcomeRoutes } from '@/modules/welcome';
import { mountAuditsRoutes } from '@/modules/admin/audits';
import { ProtectedRoute } from './components/ProtectedRoute';

export const router = (queryClient: QueryClient) => {
  const { t, i18n } = useTranslation();

  // Helpers to be passed to route mounters
  const tools: RoutesMounterArgs['tools'] = {
    t,
    queryClient,
    i18n,
  };

  return createBrowserRouter([
    {
      path: '/',
      errorElement: <ErrorPage />,
      children: [
        {
          path: 'v2',
          children: [
            {
              path: 'login',
              loader: redirectTo('/v2/auth/login', { keepSearch: true }),
            },
            {
              path: 'forgot_password',
              loader: redirectTo('/v2/auth/forgot_password', {
                keepSearch: true,
              }),
            },
            {
              path: 'signup',
              loader: redirectTo('/v2/auth/signup', { keepSearch: true }),
            },
            {
              path: 'reset_password/:key',
              loader: redirectTo(
                ({ key }) => `/v2/auth/reset_password/${key}`,
                { keepSearch: true },
              ),
            },
            ...mountAuthenticationRoutes({ path: 'auth', tools }),
            ...mountWelcomeRoutes({ path: 'welcome', tools }),
            ...mountDebugRoutes({ path: 'debug', tools }),
            ...mountGdprRoutes({ path: 'gdpr', tools }),
            {
              path: 'admin',
              loader: buildAuthenticatedLoader(tools, onlyAdminFilter),
              element: (
                <ProtectedRoute>
                  <AdminLayout />
                </ProtectedRoute>
              ),
              children: [
                ...mountRegisteredClientsRoutes({
                  path: 'registered_clients',
                  tools,
                }),
                ...mountCapGeminiRoutes({
                  path: 'capgemini',
                  tools,
                }),
                ...mountSubcontractorsRoutes({
                  path: 'subcontractors',
                  tools,
                }),
                ...mountSubcontractorBillsRoutes({
                  path: 'subcontractor_bills',
                  tools,
                }),
                ...mountUsersRoutes({ path: 'users', tools }),
                ...mountMultipartOperationRoutes({ path: 'operations', tools }),
                ...mountBankTransferRoutes({ path: 'bank_transfer', tools }),
                ...mountAccountingCodesRoutes({
                  path: 'accounting_codes',
                  tools,
                }),
                ...mountAccountingJournalExportsRoutes({
                  path: 'accounting_journal_exports',
                  tools,
                }),
                ...mountPayslipAdminRoutes({
                  path: 'payslips',
                  tools,
                }),
                ...mountBankReconciliationRoutes({
                  path: 'bank_reconciliation',
                  tools,
                }),
                ...mountAdminInvoicesRoutes({
                  path: 'invoices',
                  tools,
                }),
                ...mountFactorTransfersRoutes({
                  path: 'factor_transfers',
                  tools,
                }),
                ...mountFactorReconciliationRoutes({
                  path: 'factor_reconciliation',
                  tools,
                }),
                ...mountFactorAuthorizationRoutes({
                  path: 'factor_authorizations',
                  tools,
                }),
                ...mountFactorOutstandingInvoicesRoutes({
                  path: 'factor_outstanding_invoices',
                  tools,
                }),
                ...mountAdminCreditsRoutes({
                  path: 'credits',
                  tools,
                }),
                ...mountAuditsRoutes({
                  path: 'audits',
                  tools,
                }),
                { path: '*', Component: NotFoundPage },
              ],
            },
            {
              path: 'employees/:current_employee',
              element: (
                <ProtectedRoute>
                  <EmployeeLayout />
                </ProtectedRoute>
              ),
              loader: buildAuthenticatedLoader(tools, expectProspectFilter),
              children: [
                ...mountActivityAccountRoutes({
                  path: 'activity_account',
                  tools,
                }),
                ...mountPayslipsRoutes({ path: 'payslips', tools }),
                ...mountPerksRoutes({ path: 'my_perks', tools }),
                ...mountVideoTutorialRoutes({ path: 'video_tutorials', tools }),
                ...mountInvoicesRoutes({ path: 'invoices', tools }),
                ...mountEmployeeSubcontractorsRoutes({
                  path: 'subcontractors',
                  tools,
                }),
                ...mountEmployeeSubcontractorBillsRoutes({
                  path: 'subcontractor_bills',
                  tools,
                }),
                { path: '*', Component: NotFoundPage },
              ],
            },
          ],
        },
      ],
    },
  ]);
};
