import {
  Control,
  Controller,
  FieldError,
  FieldPath,
  FieldPathValue,
  FieldValues,
} from 'react-hook-form';
import { Override } from '@/types';
import { GroupBase } from 'react-select';
import { Error } from '@common/components/inputs/components/Error';
import { isMulti, Select, SelectProps } from '../Select';

export type ControlledSelectProps<
  T extends { label: string; value: FieldPathValue<TFieldValues, TName> },
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean,
> = Override<
  Omit<SelectProps<T, IsMulti>, 'onChange' | 'value'>,
  {
    control: Control<TFieldValues> | undefined;
    defaultValue?: FieldPathValue<TFieldValues, TName> | undefined;
    name: TName;
    onChange?: (
      value: FieldPathValue<TFieldValues, TName>[] | undefined,
    ) => void;
    errorText?: string | FieldError | undefined;
  }
>;

function isGroupOptions<T>(option: T | GroupBase<T>): option is GroupBase<T> {
  return Object.hasOwnProperty.call(option, 'options');
}

export function findValueOption<
  T extends { label: string; value: FieldPathValue<TFieldValues, TName> },
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean,
>(
  options: ControlledSelectProps<T, TFieldValues, TName, IsMulti>['options'],
  value: FieldPathValue<TFieldValues, TName>,
): T | undefined {
  if (!options) return undefined;

  const flattenOptions: T[] = options.reduce((acc, option) => {
    if (isGroupOptions(option)) {
      return [...acc, ...option.options];
    }

    return [...acc, option];
  }, [] as T[]);

  return flattenOptions.find((option) => option.value === value);
}

export const ControlledSelect = <
  T extends { label: string; value: FieldPathValue<TFieldValues, TName> },
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(
  {
    control,
    defaultValue,
    options,
    name,
    onChange: externalOnChange,
    errorText,
    ...props
  }: ControlledSelectProps<T, TFieldValues, TName, boolean>, // TODO handle isMulti
) => {
  const errorMsg =
    typeof errorText === 'string' ? errorText : errorText?.message;
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{ required: props.required }}
      render={({ field: { onChange, value, onBlur } }) => (
        <div>
          <Select
            className={errorText ? 'border-color-failure' : ''}
            options={options}
            value={findValueOption(options, value)}
            onBlur={onBlur}
            onChange={(val) => {
              const newValue = isMulti(val)
                ? val.map((singleVal) => singleVal.value)
                : val?.value;
              onChange(newValue);
              if (externalOnChange) {
                externalOnChange(newValue);
              }
            }}
            {...props}
          />
          {errorText && <Error>{String(errorMsg)}</Error>}
        </div>
      )}
    />
  );
};
