import classNames from 'classnames';
import useBreakpoints from '../../../../app/responsive/useBreakPoints';

type StatusComponentProps = {
  icon: string;
  alt: string;
  statusClass: string;
  textStyle: string;
  title: string;
  inline?: boolean;
};

function StatusComponent({
  icon,
  alt,
  statusClass,
  textStyle,
  title,
  inline,
}: StatusComponentProps) {
  const { isXs } = useBreakpoints();
  const customClassName = classNames({
    'items-center': isXs,
    'inline-flex': inline,
    flex: !inline,
  });
  const Container = inline ? 'span' : 'div';

  return (
    <Container className={customClassName}>
      <img
        src={icon}
        alt={alt}
        className="block sm:hidden h-6 w-6 md:h-4 md:w-4"
      />
      <div className={statusClass}>
        <p className={textStyle}>{title}</p>
      </div>
    </Container>
  );
}

export default StatusComponent;
