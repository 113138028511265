/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  Row,
  RowData,
  Table as ReactTable,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import { FunctionComponent, ReactNode, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { prependExpendingColumn } from '@common/components/table/hooks/prependExpendingColumn';
import {
  OnRowClickType,
  useOnRowClickTypeNormalizer,
} from '@common/components/table/useBuildOnRowClickHandler';
import { useControlledState } from '@/app/hooks/useControlledState';
import { ColDisplayOption } from './components/ColDisplayOption';
import {
  ColgroupRow,
  EmptyTableLayout,
  HeaderRow,
  HeaderTitle,
  LoadingTableLayout,
  SelectionRow,
  TablePaginationFooter,
  TableRow,
} from './components';
import { ContextedColumnFilterUpdater, prependSelectionColumn } from './hooks';
import { SelectValueType } from '../inputs/Select';

// This is a real any because `unknown` force us to use an accessorFn
// instead of just a key
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ColumnDefs<TData extends RowData> = Array<ColumnDef<TData, any>>;

export type TableStyle = 'normal' | 'compact';

export type RowSubcomponent<T> = FunctionComponent<{
  row: Row<T>;
  original: T;
}>;

export type TableProps<TData extends RowData> = {
  data: Array<TData>;
  emptyTableErrorMsg?: string;
  availablePageSizes?: SelectValueType[];

  tableStyle?: TableStyle;

  title?: string;
  disabledPagination?: boolean;

  className?: string;
  tableInformations?: ReactNode | string;
  isLoading?: boolean;
  columns: ColumnDefs<TData>;
  collapsable?: boolean;

  // Initial state for is collapsed. Useless when isCollapsed is used. Default to false
  initialIsCollapsed?: boolean;

  // Used a controlled state for isCollapsed
  isCollapsed?: boolean;

  // Used a controlled state for isCollapsed
  onIsCollapsedChange?: (newState: boolean) => void;

  enableRowSelection?: boolean;
  withImageErrorMsg?: boolean;
  displayToggleColumns?: boolean;

  totalCount?: number;
  getSubRows?:
    | ((originalRow: TData, index: number) => Array<TData> | undefined)
    | undefined;

  RowSubcomponent?: RowSubcomponent<TData> | undefined;
  getRowCanExpand?: ((row: Row<TData>) => boolean) | undefined;

  reactTableProps?: Partial<
    TableOptions<TData> & {
      onColumnFiltersChange: ContextedColumnFilterUpdater<TData>;
    }
  >;
  onRowClick?: OnRowClickType<TData>;
};

// eslint-disable-next-line func-names
const buildReactTableProps = function <TData extends RowData>(
  props: Partial<
    TableOptions<TData> & {
      onColumnFiltersChange: ContextedColumnFilterUpdater<TData>;
    }
  >,
  getTable: () => ReactTable<TData>,
) {
  const returnedProps = props;
  const { onColumnFiltersChange } = returnedProps;

  const onColumnFiltersChangeWithTable: OnChangeFn<ColumnFiltersState> &
    ContextedColumnFilterUpdater<TData> = useCallback(
    (updaterOrValue) => {
      if (onColumnFiltersChange) {
        onColumnFiltersChange(updaterOrValue, getTable());
      }
    },
    [getTable, onColumnFiltersChange],
  );

  if (onColumnFiltersChange) {
    returnedProps.onColumnFiltersChange = onColumnFiltersChangeWithTable;
  }
  return returnedProps;
};

type TableContentProps<TData extends RowData> = {
  isHidden: boolean;
  table: ReactTable<TData>;
  isLoading: boolean;
  withImageErrorMsg: TableProps<TData>['withImageErrorMsg'];
  emptyTableErrorMsg: TableProps<TData>['emptyTableErrorMsg'];
  tableStyle: NonNullable<TableProps<TData>['tableStyle']>;
  RowSubcomponent: TableProps<TData>['RowSubcomponent'];
  onRowClick: TableProps<TData>['onRowClick'];
};

function TableContent<TData extends RowData>({
  isHidden,
  table,
  isLoading,
  withImageErrorMsg,
  emptyTableErrorMsg,
  tableStyle,
  RowSubcomponent,
  onRowClick,
}: TableContentProps<TData>) {
  const onRowClickNormalizer = useOnRowClickTypeNormalizer(onRowClick);

  if (isHidden) return null;

  const { rows } = table.getRowModel();

  return (
    <table
      className="overflow-x-auto overflow-y-visible min-w-full table-fixed"
      cellPadding="0"
    >
      <ColgroupRow table={table} />
      <HeaderRow table={table} />

      {isLoading ? (
        <LoadingTableLayout table={table} />
      ) : rows.length === 0 ? (
        <EmptyTableLayout
          withImageErrorMsg={withImageErrorMsg}
          emptyTableErrorMsg={emptyTableErrorMsg}
          table={table}
        />
      ) : (
        <tbody className="">
          <SelectionRow table={table} />
          {rows.map((row) => (
            <TableRow
              row={row}
              key={row.id}
              style={tableStyle}
              onRowClickNormalizer={onRowClickNormalizer}
              RowSubcomponent={RowSubcomponent}
            />
          ))}
        </tbody>
      )}
    </table>
  );
}

function Table<TData extends RowData>({
  data,
  title,
  tableInformations,
  columns,
  collapsable = false,
  isLoading = false,
  initialIsCollapsed = false,
  isCollapsed,
  onIsCollapsedChange,
  enableRowSelection = false,
  reactTableProps = {},
  totalCount,
  className,
  onRowClick,
  emptyTableErrorMsg,
  disabledPagination = false,
  displayToggleColumns = false,
  withImageErrorMsg,
  getSubRows,
  availablePageSizes,
  tableStyle = 'normal',
  RowSubcomponent,
  getRowCanExpand,
}: TableProps<TData>) {
  const containerTableClassName = classNames(
    'max-w-full text-sm2 relative shadow-table overflow-visible overflow-x-auto',
    className,
  );

  const [internalIsCollapsed, setInternalIsCollapsed] = useControlledState(
    isCollapsed,
    initialIsCollapsed,
    onIsCollapsedChange,
  );

  const enableExpanding = !!getSubRows || !!RowSubcomponent;
  const actualGetRowCanExpand = useMemo(
    () => (RowSubcomponent ? getRowCanExpand ?? (() => true) : getRowCanExpand),
    [getRowCanExpand, RowSubcomponent],
  );

  const columnsWithExpending = prependExpendingColumn(columns, enableExpanding);
  const columnsWithSelection = prependSelectionColumn(
    columnsWithExpending,
    enableRowSelection,
  );

  const actualTotalCount = totalCount || data?.length;
  const pageSize = reactTableProps?.state?.pagination?.pageSize;

  const pageCount = disabledPagination
    ? 1
    : totalCount && pageSize && pageSize !== 0
    ? Math.ceil(totalCount / pageSize)
    : -1;

  const table: ReactTable<TData> = useReactTable<TData>({
    ...buildReactTableProps(reactTableProps, () => table),
    data: data ?? [],
    columns: columnsWithSelection,
    enableRowSelection,
    enableExpanding,
    getRowCanExpand: actualGetRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: disabledPagination
      ? undefined
      : getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSubRows,
    getExpandedRowModel: getExpandedRowModel(),
    pageCount,
    defaultColumn: {
      cell: ({ getValue }) => {
        const value = getValue();
        if (value === undefined || value === null || value === '')
          return <p className="text-center">-</p>;
        return value;
      },
    },
  });

  return (
    <>
      <div className={containerTableClassName}>
        {displayToggleColumns && <ColDisplayOption table={table} />}
        {(title || collapsable) && (
          <HeaderTitle
            collapsable={collapsable}
            title={title}
            rightComponent={<p className="mr-[18px]">{tableInformations}</p>}
            onIsCollapsedChange={setInternalIsCollapsed}
            isCollapsed={internalIsCollapsed}
          />
        )}
        <TableContent
          isHidden={internalIsCollapsed}
          table={table}
          isLoading={isLoading}
          withImageErrorMsg={withImageErrorMsg}
          emptyTableErrorMsg={emptyTableErrorMsg}
          tableStyle={tableStyle}
          RowSubcomponent={RowSubcomponent}
          onRowClick={onRowClick}
        />
      </div>
      {!disabledPagination && (
        <TablePaginationFooter
          table={table}
          totalCount={actualTotalCount}
          availablePageSizes={availablePageSizes}
        />
      )}
    </>
  );
}

export default Table;
