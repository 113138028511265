import { RoutesMounter } from '@/types';
import { index, member, redirectTo } from '@/app/navigation/functions';
import { AuditsExportsIndex } from '@/modules/admin/audits/pages/AuditsExportsIndex';
import {
  AuditsExportShow,
  queryOpts as auditsExportQueryOpts,
} from '@/modules/admin/audits/pages/AuditsExportShow';

import { buildAuditsExportFindQueryLoader } from '@/modules/admin/audits/functions/auditsExportQuery';
import { AuditsExportNew } from '@/modules/admin/audits/pages/AuditsExportNew';

export const mountAuditsRoutes: RoutesMounter<unknown> = ({
  path,
  tools: { queryClient, t },
}) => [
  member({
    path,
    handle: { crumb: () => 'Audits comptables' },
    children: [
      index({ loader: redirectTo('exports') }),
      member({
        path: 'exports',
        handle: { crumb: () => 'Exports' },
        children: [
          index({ Component: AuditsExportsIndex }),
          member({
            path: 'new',
            handle: { crumb: () => t('views.new') },
            Component: AuditsExportNew,
          }),
          member({
            path: ':auditsExportId',
            handle: {
              crumb: ({ params }) => `Export ${params.auditsExportId}`,
            },
            loader: buildAuditsExportFindQueryLoader(queryClient, {
              idParam: 'auditsExportId',
              queryOpts: auditsExportQueryOpts,
            }),
            Component: AuditsExportShow,
          }),
        ],
      }),
    ],
  }),
];
