import type { FunctionComponent } from 'react';
import { PageLayout } from '@common/components/PageLayout';
import Table from '@common/components/table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { ButtonPrimary } from '@common/components/buttons';
import AuditsExport from '@spraypaint/audits/AuditsExport';
import { useQuery } from '@tanstack/react-query';
import { useBackendTable } from '@common/components/table/hooks';
import { PlusIcon } from '@/app/assets/icons/buttons';
import Status from '@common/components/statuses/Status';
import {
  getMultiSelectFilterFn,
  MultiSelectFilter,
  multiSelectFilterWithOptions,
} from '@common/components/table/filter/MultiSelectFilter';
import { useTranslation } from 'react-i18next';
import { subformModules } from '@/modules/admin/audits/components/submodules';
import { LocalTimeDate } from '@/app/components/LocalDatetime';

const ch = createColumnHelper<ModelRecord<AuditsExport>>();
const columns = [
  ch.accessor('id', { header: 'ID' }),
  ch.accessor((original) => original.exportStrategy?.jsonapiType, {
    id: 'jsonApiType',
    header: 'Type',
    cell: ({ getValue }) => {
      const value = getValue() as string | undefined;
      const { t } = useTranslation();
      const key = value?.split('-')?.pop();
      return value ? (
        <span>
          {t(`activerecord.models.audits/exports/${key}`, {
            ns: 'rails',
          })}
        </span>
      ) : undefined;
    },
    filterFn: getMultiSelectFilterFn(),
    meta: {
      filter: {
        Component: (props: Parameters<typeof MultiSelectFilter>[0]) => {
          const { t } = useTranslation();
          const options = subformModules.map((module) => {
            const key = module.jsonApiType.split('-').pop();
            return {
              label: t(`activerecord.models.audits/exports/${key}`, {
                ns: 'rails',
              }),
              value: module.jsonApiType,
            };
          });
          return <MultiSelectFilter {...props} options={{ options }} />;
        },
      },
    },
  }),
  ch.accessor('createdAt', {
    header: 'Date de création',
    cell: ({ row: { original } }) => (
      <span>
        <LocalTimeDate date={original.parsedCreatedAt} />
      </span>
    ),
  }),
  ch.accessor('status', {
    header: 'Statut',
    cell: ({ cell }) => {
      const value = cell?.getValue();
      return value ? <Status statusType={value} /> : undefined;
    },
    filterFn: getMultiSelectFilterFn(),
    meta: {
      filter: {
        Component: multiSelectFilterWithOptions([
          { value: 'created', label: 'Créé' },
          { value: 'scheduled', label: 'Planifié' },
          { value: 'running', label: 'En cours' },
          { value: 'success', label: 'Terminé avec succès' },
          { value: 'error', label: 'Erreur' },
        ]),
      },
    },
  }),
  ch.display({
    id: 'actions',
    cell: ({ row: { original } }) => {
      if (!original) return null;

      return (
        <div className="flex">
          <ButtonPrimary to={`./${original.id}`} className="px-[35px] mx-2">
            Voir
          </ButtonPrimary>
        </div>
      );
    },
  }),
];

export const AuditsExportsIndex: FunctionComponent = () => {
  const [scope, scopeKey, reactTableProps] = useBackendTable<AuditsExport>();

  const { data, isLoading } = useQuery({
    queryKey: ['auditsExports', scopeKey],
    queryFn: () =>
      scope(
        AuditsExport.includes(['export_strategy']).order({ createdAt: 'desc' }),
      ).all(),
  });

  const totalCount = data?.meta.stats.total.count;

  return (
    <PageLayout
      Title="Export audits comptable"
      Buttons={
        <ButtonPrimary to="new" Icon={PlusIcon} className="mr-2">
          Nouvel Export
        </ButtonPrimary>
      }
    >
      <Table
        data={data?.data ?? []}
        columns={columns}
        totalCount={totalCount}
        isLoading={isLoading}
        reactTableProps={reactTableProps}
        onRowClick="navigate"
      />
    </PageLayout>
  );
};
